import * as React from 'react';
import Box from "@mui/material/Box";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import getLPTheme from "./getLPTheme";
import CssBaseline from "@mui/material/CssBaseline";
import AppAppBar from "./components/AppAppBar";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/PrivacyPolicy";

export default function PrivacyPolicyPage() {
    const [mode] = React.useState('dark');
    const [showCustomTheme] = React.useState(true);
    const LPtheme = createTheme(getLPTheme(mode));
    const defaultTheme = createTheme({palette: {mode}});

    return (
        <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
            <CssBaseline/>
            <AppAppBar/>
            <PrivacyPolicy/>
            <Box sx={{bgcolor: '#14181b'}}>

                <Footer/>
            </Box>
        </ThemeProvider>
    );
}