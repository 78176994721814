import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const tiers = [
    {
        title: 'Starter Pack',
        price: '0.99',
        description: [
            '10 Coins',
            'Perfect for new users to get started and explore premium features.'
        ],
        buttonText: 'Sign up for free',
        buttonVariant: 'outlined',
    },
    {
        title: 'Value Pack',
        price: '9.99',
        subheader: 'Recommended',
        description: [
            '100 Coins',
            'Ideal for regular users who want to maximize their experience.'
        ],
        buttonText: 'Sign up for free',
        buttonVariant: 'outlined',
        recommend: true
    },
    {
        title: 'Basic Pack',
        price: '4.99',
        description: [
            '50 Coins',
            'A great value for users looking to unlock more features and benefits.'
        ],
        buttonText: 'Sign up for free',
        buttonVariant: 'outlined'
    },
    {
        title: 'Super Saver Pack',
        price: '19.99',
        description: [
            '250 Coins',
            'Best for dedicated users, offering substantial savings on bulk purchases.'
        ],
        buttonText: 'Sign up for free',
        buttonVariant: 'outlined'
    },
    {
        title: 'Ultimate Pack',
        price: '34.99',
        description: [
            '500 Coins',
            'Perfect for power users, providing the best value and maximum benefits.'
        ],
        buttonText: 'Sign up for free',
        buttonVariant: 'outlined'
    }
];

export default function Pricing() {
    return (
        <Container
            id="pricing"
            sx={{
                pt: {xs: 4, sm: 12},
                pb: {xs: 8, sm: 16},
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: {xs: 3, sm: 6},
            }}
        >
            <Box
                sx={{
                    width: {sm: '100%', md: '60%'},
                    textAlign: {sm: 'left', md: 'center'},
                }}
            >
                <Typography variant="subtitle1" color='#95A1AC' sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                            fontWeight="bold" fontSize={{
                    lg: 60,
                    md: 50,
                    sm: 40,
                    xs: 30
                }} gutterBottom>
                    Pricing
                </Typography>
                <Typography variant="subtitle2" color='#95A1AC'
                            sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={15}
                            gutterBottom>
                    For users who want the ultimate experience with extensive premium features and savings.
                </Typography>
            </Box>
            <Grid container spacing={3} alignItems="center" justifyContent="center">
                {tiers.map((tier) => (
                    <Grid
                        item
                        key={tier.title}
                        xs={12}
                        sm={tier.title === 'Enterprise' ? 12 : 6}
                        md={4}
                    >
                        <Card
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 4,
                                border: tier.recommend ? '1px solid' : undefined,
                                borderColor:
                                    tier.recommend ? '#6f61ef' : undefined,
                                background:
                                    tier.recommend
                                        ? 'rgba(111, 97, 239, 0.2)'
                                        : undefined,
                            }}
                        >
                            <CardContent>
                                <Box
                                    sx={{
                                        mb: 1,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        fontFamily: '"Plus Jakarta Sans", sans-serif',
                                        alignItems: 'center',
                                        color: tier.title === 'Annual' ? 'grey.100' : '',
                                    }}
                                >
                                    <Typography component="h3" sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                                fontWeight="bold" variant="h6">
                                        {tier.title}
                                    </Typography>
                                    {tier.recommend && (
                                        <Chip
                                            icon={<AutoAwesomeIcon/>}
                                            label={tier.subheader}
                                            size="small"
                                            sx={{
                                                backgroundColor: '#6f61ef',
                                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                                color: '#FFFFFF',
                                                fontWeight: '700',
                                                '& .MuiChip-icon': {
                                                    color: '#FFBF00',
                                                },
                                            }}
                                        />
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'baseline',
                                        color: tier.recommend ? 'grey.50' : undefined,
                                    }}
                                >
                                    <Typography component="h3" sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                                fontWeight="bold" variant="h2">
                                        ${tier.price}
                                    </Typography>
                                </Box>
                                <Divider
                                    sx={{
                                        my: 2,
                                        opacity: 0.2,
                                        borderColor: 'grey.500',
                                    }}
                                />
                                {tier.description.map((line) => (
                                    <Box
                                        key={line}
                                        sx={{
                                            py: 1,
                                            display: 'flex',
                                            gap: 1.5,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CheckCircleRoundedIcon
                                            sx={{
                                                width: 20,
                                                color: '#6f61ef',
                                            }}
                                        />
                                        <Typography
                                            component="text"
                                            variant="subtitle2"
                                            sx={{
                                                fontFamily: '"Plus Jakarta Sans", sans-serif',
                                                color:
                                                    tier.recommend ? 'grey.200' : undefined,
                                            }}
                                        >
                                            {line}
                                        </Typography>
                                    </Box>
                                ))}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
