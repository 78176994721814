import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MagicIcon from '@mui/icons-material/AutoAwesome';
import Grid from "@mui/material/Grid";
import ss from '../images/image.png'

export default function Hero() {
    return (
        <Box
            id="hero"
            sx={{
                width: '100%',
                backgroundColor: '#14181b'
            }}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                    pt: {xs: 15, sm: 15},
                    pb: {xs: 8, sm: 20, lg: 0},
                }}
            >
                <Stack spacing={2} useFlexGap sx={{width: {xs: '100%', sm: '75%'}}}>
                    <Typography variant="subtitle1" color='#fff' sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                fontWeight="600" fontSize={{
                        lg: 60,
                        md: 50,
                        sm: 40,
                        xs: 30
                    }} gutterBottom>
                        Scan, Analyze, Transform Your Health with ReportIQ <MagicIcon
                        sx={{color: '#FFBF00', fontSize: {
                                lg: 60,
                                md: 50,
                                sm: 40,
                                xs: 30
                            }}}/> </Typography>
                </Stack>
                <Box
                    sx={{
                        mt: {xs: 0, sm: 10},
                        height: {xs: 200, sm: 700},
                    }}
                >
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Stack direction="row" justifyContent="center">
                                <Box
                                    sx={{
                                        height: {xs: 470, sm: 600, lg: 730},
                                    }}
                                    component="img"
                                    alt="Ss"
                                    src={ss}
                                />
                            </Stack>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            direction="column"
                            sx={{width: '100%'}}
                        >
                            <Typography variant="subtitle1" color='#FFFFFF'
                                        sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={35}
                                        gutterBottom>
                                Your Health, Simplified with ReportIQ
                            </Typography>
                            <Typography variant="subtitle2" color='#95A1AC'
                                        sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={15}
                                        gutterBottom>
                                Scan your lab reports and food items with ease. ReportIQ analyzes your health metrics and provides tailored recommendations, suitability ratings, and personalized diet plans. Save your health data securely, and access instant insights and nutrition tweaks based on your lab results. Review your tailored suggestions and make informed choices for a healthier you.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}
