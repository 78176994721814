import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Container} from '@mui/material';
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ChecklistIcon from '@mui/icons-material/Checklist';
import Divider from "@mui/material/Divider";

export default function ModelCard() {
    return (
        <Box
            id="model-card"
            sx={{
                width: '100%',
                backgroundColor: '#14181b'
            }}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: {xs: 80, sm: 40, lg: 15},
                    pb: {xs: 10, sm: 12},
                }}
            >
                <Grid container spacing={4} alignItems="start">
                    <Grid
                        item
                        xs={12}
                        md={4}
                        direction="column"
                        sx={{width: '100%'}}
                    >
                        <MonitorHeartIcon
                            sx={{color: '#6f61ef', fontSize: {
                                    lg: 60,
                                    md: 50,
                                    sm: 40,
                                    xs: 30
                                }}}/>
                        <Divider/>
                        <Typography variant="subtitle1" color='#FFFFFF'
                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif', fontWeight: '600'}} fontSize={25}
                                    gutterBottom>
                            Instant Health Insights
                        </Typography>
                        <Typography variant="subtitle2" color='#95A1AC'
                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={15}
                                    gutterBottom>
                            Scan your lab test reports effortlessly with ReportIQ. Our app provides a comprehensive analysis of your health metrics, giving you instant insights and detailed recommendations to improve your health. With just a quick scan, you can understand your health better and take actionable steps towards wellness.                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        direction="column"
                        sx={{width: '100%'}}
                    >
                        <RestaurantIcon
                            sx={{color: '#6f61ef', fontSize: {
                                    lg: 60,
                                    md: 50,
                                    sm: 40,
                                    xs: 30
                                }}}/>
                        <Divider/>
                        <Typography variant="subtitle1" color='#FFFFFF'
                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif', fontWeight: '600'}} fontSize={25}
                                    gutterBottom>
                            Personalized Food Analysis
                        </Typography>
                        <Typography variant="subtitle2" color='#95A1AC'
                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={15}
                                    gutterBottom>
                            Not sure what to eat? With ReportIQ, simply scan your food items and receive an analytical breakdown based on your lab reports. The app provides a suitability rating tailored just for you, along with further nutrition tweaks to align the food with your health needs. Instantly see how each food fits into your diet according to your lab results.                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                        direction="column"
                        sx={{width: '100%'}}
                    >
                        <ChecklistIcon
                            sx={{color: '#6f61ef', fontSize: {
                                    lg: 60,
                                    md: 50,
                                    sm: 40,
                                    xs: 30
                                }}}/>
                        <Divider/>
                        <Typography variant="subtitle1" color='#FFFFFF'
                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif', fontWeight: '600'}} fontSize={25}
                                    gutterBottom>
                            Customized Diet Plans
                        </Typography>
                        <Typography variant="subtitle2" color='#95A1AC'
                                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={15}
                                    gutterBottom>
                            ReportIQ can generate a customized 7-day diet plan based on your lab reports and personal preferences. No more guesswork or fad diets – get clear, personalized nutrition advice designed to help you feel your best. Follow a diet plan that's perfectly aligned with your health metrics and dietary preferences for optimal results.                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}