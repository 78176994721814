import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Policy from "@mui/icons-material/Policy";
import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

export default function PrivacyPolicy() {
    return (
        <Box
            id="hero"
            sx={{
                width: '100%',
                backgroundColor: '#14181b'
            }}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    pt: {xs: 14, sm: 20},
                    pb: {xs: 8, sm: 12, lg: 0},
                }}
            >
                <Stack spacing={2} useFlexGap sx={{width: {xs: '100%', sm: '70%'}}}>
                    <Typography variant="subtitle1" color='#fff' sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                fontWeight="700" fontSize={{
                        lg: 45,
                        md: 45,
                        sm: 40,
                        xs: 30
                    }} gutterBottom>
                        Privacy Policy <Policy
                        sx={{
                            color: '#EECC6E', fontSize: {
                                lg: 55,
                                md: 50,
                                sm: 40,
                                xs: 30
                            }
                        }}/></Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Effective Date: 2024-08-01 </Typography>
                    <Typography variant="subtitle2" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        This Privacy Policy describes how ReportIQ, a Freemium app developed by ReportIQ, collects, uses, and discloses your information. We are committed to protecting your privacy and ensuring you understand how we handle your data. By using ReportIQ, you agree to the collection and use of information in accordance with this policy. </Typography>
                    <Typography variant="subtitle2" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        By using ReportIQ, you agree to the collection and use of information in accordance with this
                        policy. </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Information Collection and Use </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        To provide you with the best ReportIQ experience, we may collect the following information:                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        <ul>
                            <li>Lab Reports: Scanned lab reports are used to suggest suitable foods and generate diet plans. These reports are stored securely and are not shared with anyone except through integration with third-party services such as Google Gemini.</li>
                            <li>Email: Used to create an account and send you important updates about the app. We will never share or sell your email address to any third party.</li>
                        </ul>
                    </Typography>

                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        We will never share or sell your email address to any third party.
                    </Typography>

                    {/*Log Data*/}
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Log Data
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        When you use ReportIQ, we collect standard log data (through third-party products) on your device, including:
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        <ul>
                            <li>Device Internet Protocol (IP) address</li>
                            <li>Device name</li>
                            <li>Operating system version</li>
                            <li>App usage data (time, date, etc.)</li>
                            <li>Other statistics</li>
                        </ul>
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        This data helps us diagnose errors, improve the app, and understand how users interact with ReportIQ.
                    </Typography>

                    {/*Third-Party Services*/}
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Third-Party Services
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        ReportIQ may integrate with third-party services that collect information used to identify you. These services have their own privacy policies, which we encourage you to review:                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        <ul>
                            <li><a href="https://www.google.com/policies/privacy/">Google Play Services</a></li>
                            <li><a href="https://firebase.google.com/support/privacy">Google Analytics for Firebase</a></li>
                            <li><a href="https://www.revenuecat.com/privacy">RevenueCat</a></li>
                            <li><a href="https://support.google.com/gemini/answer/13594961?hl=en#privacy_notice">Google Gemini</a></li>
                        </ul>
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        We do not control or endorse the practices of any third-party services.
                    </Typography>

                    {/*Cookies*/}
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Cookies
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        ReportIQ does not directly use cookies. However, third-party code and libraries within the app may use cookies to collect information and improve their services. You can choose to accept or refuse these cookies through your device settings.
                    </Typography>

                    {/*Security*/}
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Security
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no internet transmission or electronic storage method is completely secure.
                    </Typography>
                    {/*Children's Privacy*/}
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Children's Privacy
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        ReportIQ is intended for users 18 years of age and older. We do not knowingly collect personal information from children under 18. If you are a parent or guardian and believe your child has provided us with information, please contact us for deletion.
                    </Typography>
                    {/*Changes to this Privacy Policy*/}
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Changes to this Privacy Policy
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        We may update this Privacy Policy periodically. We will notify you of any changes by posting the new policy here.
                    </Typography>

                    {/*Contact Us*/}
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" fontSize={18}
                                gutterBottom>
                        Contact Us
                    </Typography>
                    <Typography variant="subtitle1" color='#95A1AC'
                                sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontSize={18}
                                gutterBottom>
                        If you have any questions or concerns about our Privacy Policy, please contact us at: &nbsp;
                        reportiq77@gmail.com
                    </Typography>

                </Stack>
            </Container>
        </Box>
    );
}
