import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import logo from '../images/logo.png';
import {ButtonsContainer, GooglePlayButton} from "react-mobile-app-button";

const logoStyle = {
    width: '80px',
    height: 'auto',
};

function Copyright() {
    return (
        <Typography variant="body1" mt={1} color='#95A1AC'
                    sx={{fontFamily: '"Plus Jakarta Sans", sans-serif', fontSize: "bold"}}>
            {'© ReportIQ '}
            {new Date().getFullYear()}
            {'. All Rights Reserved.'}
        </Typography>
    );
}

const APKUrl = "https://play.google.com/store/apps/details?id=ai.reportiq.app";

export default function Footer() {
    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: {xs: 4, sm: 8},
                py: {xs: 8, sm: 10},
                textAlign: {sm: 'center', md: 'left'},
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {xs: 'column', sm: 'row'},
                    width: '100%',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 4,
                        minWidth: {xs: '100%', sm: '60%'},
                    }}
                >
                    <Box sx={{width: {xs: '100%', sm: '60%'}}}>
                        <Box sx={{ml: '-8px', mb: '5px'}}>
                            <Stack
                                direction="row"
                                justifyContent="start"
                                alignItems="center"
                                spacing={.5}
                            >
                                <img
                                    src={logo}
                                    style={logoStyle}
                                    alt="logo of sitemark"
                                />
                                <Typography ml={1} variant="subtitle1" color='#FFFFFF'
                                            sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                                            fontWeight="bold" fontSize={20} gutterBottom>
                                    ReportIQ</Typography>
                            </Stack>
                        </Box>
                        <Typography variant="body2" sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} color="#95A1AC"
                                    fontWeight="bold" mb={2}>
                            Scan. Analyze. Thrive with ReportIQ!
                        </Typography>
                        <Box sx={{
                            display: {lg: 'none', md: 'none', sm: 'none'},
                            flexDirection: 'column',
                            gap: 1,
                        }}>
                            <ButtonsContainer direction="row">
                                <GooglePlayButton
                                    url={APKUrl}
                                    theme={"dark"}
                                    className={"custom-style"}
                                />
                            </ButtonsContainer>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: {xs: 'none', sm: 'flex'},
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                </Box>
                <Box
                    sx={{
                        display: {xs: 'none', sm: 'flex'},
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold" gutterBottom>
                        Download
                    </Typography>
                    <ButtonsContainer direction="column">
                        <GooglePlayButton
                            url={APKUrl}
                            theme={"dark"}
                            className={"custom-style"}
                        />
                    </ButtonsContainer>
                </Box>
                <Box
                    sx={{
                        display: {xs: 'none', sm: 'flex'},
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <Typography variant="body2" sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} fontWeight="bold">
                        Legal
                    </Typography>
                    <Link color="text.secondary" sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} href="/terms-of-use">
                        Terms
                    </Link>
                    <Link color="text.secondary" sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                          href="/privacy-policy">
                        Privacy
                    </Link>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    pt: {xs: 4, sm: 8},
                    width: '100%',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <div>
                    <Link color="text.secondary" sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}}
                          href="/privacy-policy">
                        Privacy Policy
                    </Link>
                    <Typography display="inline" sx={{mx: 0.5, opacity: 0.5}}>
                        &nbsp;•&nbsp;
                    </Typography>
                    <Link color="text.secondary" sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} href="/terms-of-use">
                        Terms of Service
                    </Link>
                    <Typography display="inline" sx={{mx: 0.5, opacity: 0.5}}>
                        &nbsp;•&nbsp;
                    </Typography>
                    <Link color="text.secondary" sx={{fontFamily: '"Plus Jakarta Sans", sans-serif'}} href="/">
                        Home
                    </Link>
                    <Copyright/>
                </div>
            </Box>
        </Container>
    );
}