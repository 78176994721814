import './App.css';
import LandingPage from "./LandingPage";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import PrivacyPolicyPage from "./PrivacyPolicyPage";
import TermsOfUsePage from "./TermsOfUsePage";

function App() {
  return (
    <div className="App">
        <Router>
            <Routes>
                <Route
                    exact
                    path="/"
                    element={<LandingPage/>}/>
                <Route
                    exact
                    path="/privacy-policy"
                    element={<PrivacyPolicyPage/>}/>
                <Route
                    exact
                    path="/terms-of-use"
                    element={<TermsOfUsePage/>}/>
            </Routes>
        </Router>
    </div>
  );
}

export default App;
